<template>
  <layout name="EmptyLayout">
    <div class="uk-container uk-text-center">
      <div 
        class="uk-card uk-card-body"
        uk-scrollspy="target: > i; cls: uk-animation-shake; delay: 500; offset-top: 2000;"
      >
        <i uk-icon="icon: warning; ratio: 12" class="uk-text-muted"></i>
        <h1 class="uk-card-title">{{ $t('Generic.Errors.PageNotFoundTitle') }}</h1>
        <p class="uk-card-body uk-text-lighter">{{ $t('Generic.Errors.PageNotFoundMessage') }}</p>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/layouts/Layout';

export default {
  name: 'NotFound',
  components: {
    Layout
  }
}
</script>

<style lang="scss" scoped>
.uk-container {
  height: 100vh;
  z-index: 10;
}
.uk-card {
  margin-top: 5%;
}
h1 {
  font-size: 32px;
}
i {
  z-index: 1;
}
</style>
